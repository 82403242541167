import {Link, NavLink, useNavigate} from "react-router-dom";
import {Turn as Hamburger} from "hamburger-react";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../service/context/AuthProvider";
import {getStatus, getUser} from "../service/api/authApi";
import useGetUser from "../service/hooks/useGetUser";
import {API} from "../service/const/api";
import "../styles/navbar.css";
import {ARTICLES_PATH, BLOGS_PATH, HOME_PATH, NEWS_PATH} from "../service/const/paths";
import coursesApi from "../service/api/coursesApi";
import axios from "axios";
import tgIcon from '../assets/icons/tg-icon.svg'

const Nav = () => {
    const {auth}: any = useContext(AuthContext); // using context
    const navigate = useNavigate()

    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const [status, setStatus] = useState<string[]>([])

    useEffect(() => {
        const getStatusHandle = async () => {
            try {
                const response = await getStatus(localStorage.getItem("token") ?? "");
                if (response?.status === 200) {
                    setStatus(response.data?.status);
                }
            } catch (e) {
                console.log(e)
            }
        };

        getStatusHandle()
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user2 = await getUser(localStorage.getItem("token")!);
                setUserName(user2.firstName);
                setUserRole(user2.role);
            } catch (error) {
                return;
            }
        };
        fetchUser();
    }, [auth]);

    const closeNavOnClick = () => {
        setIsOpened(false);
    };

    const transferToRef = (path: string, params: Object) => navigate(path, {state:params})

    const NavItem = (props: any) => {
        if (props.transfer) return (
            <div
                className={"nav-div"}
                style={{cursor:"pointer"}}
                onClick={props.onClick}
            >
                {props.text}
            </div>
        )

        return (
            <NavLink onClick={props.onClick} to={props.to} style={({isActive}) => {
                return {textDecoration: isActive ? "underline" : "", textUnderlineOffset: 2}
            }}>
                {props.text}
            </NavLink>
        )
    }

    return (
        <nav>
            <div className="nav-container">
                <div className="hamburger">
                    <Hamburger
                        toggle={setIsOpened}
                        toggled={isOpened}
                        duration={0.4}
                        direction="right"
                    />
                </div>
                <Link to="/" className="logo-container">
                    <img
                        className="logo"
                        src={`../../logo.png`}
                        alt=""
                    />
                </Link>
                {!status.includes("Student") ?
                <Link to="/account" className="user-icon-container">
                    <img
                        className="user-icon"
                        src={`${API}/files/user_icon.png`}
                        alt=""
                    />
                </Link>:<div/>}
            </div>

            <div className={isOpened ? "nav-opened" : "nav-opened hidden"}>
                <div className="nav-opened-links">
                    <NavItem onClick={closeNavOnClick} to={NEWS_PATH} text={"Новости"}/>
                    <NavItem onClick={closeNavOnClick} to={"/about"} text={"О нас"}/>
                    <NavItem onClick={closeNavOnClick} to={"/"} text={"Главная"}/>
                    {/*<NavItem onClick={closeNavOnClick} to={"/courses"} text={"Курсы"}/>*/}
                    <NavItem
                        onClick={() => {
                            transferToRef(HOME_PATH, {ref: "faq"})
                            closeNavOnClick()
                        }}
                        text={"FAQ"}
                        transfer={true}
                    />
                    {/*<NavItem onClick={closeNavOnClick} to={"/subscriptions"} text={"Подписка"}/>*/}
                    {/*<NavItem onClick={closeNavOnClick} to={ARTICLES_PATH} text={"Издание"}/>*/}
                    {/*<NavItem onClick={closeNavOnClick} to={"/radio"} text={"Радио"}/>*/}
                    {/*<NavItem onClick={closeNavOnClick} to={"/for-business"} text={"Для бизнеса"}/>*/}
                    {/*<NavItem onClick={closeNavOnClick} to={"/jobs"} text={"Вакансии"}/>*/}
                    <NavItem onClick={closeNavOnClick} to={"/test"} text={"Пройти тест"}/>
                    <Link className='tg-link' to="https://t.me/+Usa6YmqL_OLXafsL">
                        <img className="tg-icon" src={tgIcon} alt=""/>
                        Мы в телеграм
                    </Link>
                </div>
            </div>

            <div className="nav-container-big-screen">
                <div className="nav-left text-xs md:text-sm lg:text-base">
                    <Link to="/" className="logo-container">
                        <img className="logo"
                             src={`../../logo.png`}
                             alt=""
                        />
                    </Link>
                    <NavItem to={NEWS_PATH} text={"Новости"}/>
                    <NavItem to={"/about"} text={"О нас"}/>
                    <NavItem
                        onClick={() => transferToRef(HOME_PATH, {ref: "faq"})}
                        text={"FAQ"}
                        transfer={true}
                    />
                    <NavItem to={"/test"} text={"Пройти тест"}/>
                    
                    <Link className='tg-link' to="https://t.me/+Usa6YmqL_OLXafsL">
                        <img className="tg-icon" src={tgIcon} alt=""/>
                        Мы в телеграм
                    </Link>

                    {userRole && userRole === "Admin" && (
                        <Link to="/admin-panel">Панель Управления</Link>
                    )}
                </div>
                {!status.includes("Student") ? <Link to="/account" className="user-icon-container">
                    <img className="user-icon"
                         src={`${API}/files/user_icon.png`}
                         alt=""
                    />
                </Link>: null}
            </div>
        </nav>
    );
};

export default Nav;
