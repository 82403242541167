import './ExpertsCard.scss'

interface IExpertCardProps {
	title: string,
	description: string,
	imgPath: string
}

const ExpertCard = ({title, description, imgPath}: IExpertCardProps) => {
	return (
		<article className="card">
			<div className="card__image-wrapper">
				<img
					className="card__image"
					src={`${process.env.PUBLIC_URL}/faces/${imgPath}`}
					alt={title}
					loading='lazy'
				/>
			</div>
			<div className="card__body">
				<h3 className="card__title">{title}</h3>
				<p className="card__descr">{description}</p>
			</div>
		</article>
	)
}

export default ExpertCard