import AdvantagesCard from './AdvantagesCard/AdvantagesCard'
import { advantages} from './AdvantagesCard/advantages'
import './Advantages.scss'

const Advantages = () => {
	return (
		<section className='advantages'>
			<div className="advantages__container">
				<h2 className="advantages__title">Наши преимущества</h2>
				<div className="advantages__body">
					{advantages.map(advantage =>
						<AdvantagesCard
							key={advantage.title}
							title={advantage.title}
							description={advantage.description}
							modifier={advantage.modifier}
						/>
					)}
				</div>
			</div>
		</section>
	)
}

export default Advantages