import './AdvantageCard.scss'

interface IProps {
	title: string,
	description: string,
	modifier: string
}

const AdvantagesCard = ({title, description, modifier}: IProps) => {
	let classes = 'advantages__card-title'
	
	modifier ? classes += ` advantages__card-title--${modifier}` : classes += ''
	
	return (
		<div className="advantages__card">
			<h3 className={classes}>{title}</h3>
			<p className="advantages__card-descr">
				{description}
			</p>
		</div>
	)
}

export default AdvantagesCard