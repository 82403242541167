interface ExpertCard {
	title: string,
	description: string
	imgPath: string
}

export const experts: ExpertCard[] = [
	{
		title: 'Никата Зверев',
		description: 'Философ, социолог, основатель академии "Нус"',
		imgPath: 'nikita_zverev.jpg'
	},
	{
		title: 'Марина Шпан',
		description: 'Практикующий психолог, специалист в области борьбы с тревогой и стрессом',
		imgPath: 'marina_shpan.jpg'
	},
	{
		title: 'Анна Андорская',
		description: 'Бизнес-эксперт с многолетним стажем',
		imgPath: 'anna_andorskaya.jpg'
	},
	{
		title: 'Сергей Пахомов',
		description: 'Кандидат философских наук, преподаватель РГПУ им. Герцина',
		imgPath: 'sergey_pahomov.jpg'
	},
]