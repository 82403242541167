export const advantages = [
	{
		title: 'Системное обучение',
		description: 'Получите доступ к самой большой библиотеке курсов по самым разным практическим направлениям.',
		modifier: 'education'
	},
	{
		title: 'Живые встречи',
		description: 'Встречайтесь с самыми интересными и амбициозными людьми, которые неустанно стемятся к большему.',
		modifier: 'meeting'
	},
	{
		title: 'Нетворкинг',
		description: 'Присоединяйтесь к сообществу единомышленников. Знакомьтесь с теми, кто разделяет ваши ценности и обретайте полезные связи.',
		modifier: 'networking'
	},
	{
		title: 'Полезные знания',
		description: 'В нашем сообществе каждый день публикуется различный контент, а также проводятся прямые трансляции и различные мероприятия.',
		modifier: 'knowledge'
	}
]