import {experts} from './data'
import './Experts.scss'
import ExpertCard from '../ExpertsCard/ExpertCard'

const Experts = () => {
	
	return (
		<section className='experts'>
			<div className="experts__container">
				<h2 className="experts__title">
					Ведущие эксперты нашего сообщества
				</h2>
				<p className="experts__descr">
					Получите эксклюзивные обучающие материалы от экспертов нашего сообщества:
				</p>
				<ul className='experts__list'>
					{experts.map(expert =>
						<li className="experts__item" key={expert.title}>
							<ExpertCard
								title={expert.title}
								description={expert.description}
								imgPath={expert.imgPath}
							/>
						</li>
					)}
				</ul>
				<p className="experts__descr">
					Это лишь некоторые примеры. Мы регулярно приглашаем опытных специалистов для создания наших обучающих программ.
				</p>
			</div>
		</section>
	)
}

export default Experts